import './LoginScreen.css';
import React, { useState } from 'react';
import { useAuth } from '../../providers/AuthProvider'; // Update the path

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { handleLogin } = useAuth();

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        handleLogin(email, password); // Pass email and password to the handleLogin function
    };

    return (
        <div className="login-container">
            <div className='left-side'>
                <div className="login-form">
                <h1>Login ✋</h1>
                <p>How do i get started?</p>
                <button className="google-login" disabled={true}>Sign in with Google</button>
                <button className="facebook-login" disabled={true}>Sign in with Facebook</button>
                <form onSubmit={onSubmit}>
                    <div className="email-input">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" value={email} onChange={onEmailChange} required />
                    </div>
                    <div className="password-input">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" value={password} onChange={onPasswordChange} required />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
                <a href="#forgot">Forget Password?</a>
                </div>
            </div>
      </div>
    );
}

export default LoginScreen;
