import React, { useState } from 'react';
import './Shop.css'; // Make sure to create a corresponding CSS file for styling

const SearchBar = ({ onSearch }) => (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Please Search shops"
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
);
  
const NavBar = ({position, clicked}) => (
    <div class="navbar">
      <a href="#" className={`nav-item ${position==0?'active':''}`} onClick={()=>clicked(0)}>Shops</a>
      <a href="#" className={`nav-item ${position==1?'active':''}`} onClick={()=>clicked(1)}>Shop Details</a>
      <a href="#" className={`nav-item ${position==2?'active':''}`} onClick={()=>clicked(2)}>Add Shop</a>
      <a href="#" className={`nav-item ${position==3?'active':''}`} onClick={()=>clicked(3)}>Inventories</a>
    </div> 
);

const CardView = ({ shopName, address, location, contact, category }) => {
    return (
      <div className="card">
          <div className="card-content">
            <div className="card-field">
              <strong className='card-field-l'>Shop name</strong>
              <div className='card-field-r'>{shopName}</div>
            </div>
            <div className="card-field">
              <strong className='card-field-l'>Address</strong>
              <div className='card-field-r'>{address}</div>
            </div>
            <div className="card-field">
              <strong className='card-field-l'>Location</strong>
              <div className='card-field-r'>{location}</div>
            </div>
            <div className="card-field">
              <strong className='card-field-l'>Contact</strong>
              <div className='card-field-r'>{contact}</div>
            </div>
            <div className="card-field">
              <strong className='card-field-l'>Shop Category</strong>
              <div className='card-field-r'>{category}</div>
            </div>
          </div>
        </div>
    );
  };


const Shop = () => {
  const [selectedShop, setSelectedShop] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [navPosition, setNavPosition] = useState(0);

  const cardData = {
    shopName: "Shop 1",
    address: "At/po - bengaluru, margondanahalli",
    location: "10.000, 10.000",
    contact: "910000000",
    category: "Hardware Store"
  };

  const shops = [
    {id:0,name:"name",productName:"product name",owner:"sonu"},
    {id:1,name:"name",productName:"product name",owner:"sonu"},
    {id:3,name:"name",productName:"product name",owner:"sonu"}
  ];

  const clicked = (pos)=>{
    setNavPosition(pos);
  }

  return (
    <div className="shop-root">
        <div className="top-part">
            <NavBar position={navPosition} clicked={clicked}/>
            <SearchBar onSearch={setSearchTerm} />
        </div>
      
        <div className="container">
            <CardView
                shopName={cardData.shopName}
                address={cardData.address}
                location={cardData.location}
                contact={cardData.contact}
                category={cardData.category}/>
            <CardView
                shopName={cardData.shopName}
                address={cardData.address}
                location={cardData.location}
                contact={cardData.contact}
                category={cardData.category}/>
            <CardView
                shopName={cardData.shopName}
                address={cardData.address}
                location={cardData.location}
                contact={cardData.contact}
                category={cardData.category}/>
            <CardView
                shopName={cardData.shopName}
                address={cardData.address}
                location={cardData.location}
                contact={cardData.contact}
                category={cardData.category}/>
        </div>
    </div>
  );
};

export default Shop;