// Products.js
import React, {useState} from 'react';
import './Products.css'; // Make sure you have this CSS file
import { ProductProvider, useProduct } from '../../providers/ProductsProvider';
import FileUploader from '../../utils/FileUploader';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import Ripples from 'react-ripples'
// import DataTable from '../tables/DataTable';


const Products = () => {
  
  const Main = ()=>{
    const columns = [
      { field: 'name', headerName: 'Name', width: 130 },
      { field: 'price', headerName: 'Price', type:'number', width: 70 },
      { field: 'category', headerName: 'Category', width: 150 },
      {
        field: 'sub-category',
        headerName: 'Sub Category',
        width: 150,
      },
      {
        field: 'model',
        headerName: 'Model',
        width: 150,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        width: 150,
      },
      {
        field: 'color',
        headerName: 'Color',
        width: 150,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 150,
      },
      // {
      //   field: 'fullName',
      //   headerName: 'Full name',
      //   description: 'This column has a value getter and is not sortable.',
      //   sortable: false,
      //   width: 160,
      //   valueGetter: (params) =>
      //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      // },
    ];
    const {updateField, addProduct, productAdded, products, addFiles, handleSearchChange, loader} = useProduct();
    const props = {columns: columns, rows: products}

    const handleChange = (e) => {
      updateField(e.target.id, e.target.value);
    };

    const handleRowClick = (product, event) => {
      console.log("Clicked product:", product);
    
      // Apply ripple to all cells in the row
      const cells = event.currentTarget.querySelectorAll('.ripple-effect');
      cells.forEach(cell => {
        cell.classList.add('ripple');
        setTimeout(() => cell.classList.remove('ripple'), 500); // Adjust timing
      });
    };
    

    const onSubmit = (event) => {
      event.preventDefault(); // Prevent the default form submission
      addProduct();
    };

    const handleFiles = async(files) => {
      try{
        addFiles(files);
        console.log(files)
      }catch(e){

      }
    }

    return (
      <div className="products-container">
        <h1>Add Products</h1>
        <div className="add-products">
          <form className="add-products-form" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" required onChange={handleChange} id="name" placeholder="Enter product name" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input type="text" required onChange={handleChange} id="price" placeholder="Enter product price" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="category">Category</label>
              <input type="text" required onChange={handleChange} id="category" placeholder="Enter product category" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="sub-category">Sub-category</label>
              <input type="text" onChange={handleChange} id="sub_category" placeholder="Enter product Sub-category" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="model">Model</label>
              <input type="text" required onChange={handleChange} id="model" placeholder="Enter product model" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="brand">Brand</label>
              <input type="text" required onChange={handleChange} id="brand" placeholder="Enter product brand" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="color">Color</label>
              <input type="text" onChange={handleChange} id="color" placeholder="Enter product color" maxLength="255" />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input type='text' required onChange={handleChange} id="description" placeholder="Enter product description" maxLength="3000" />
            </div>

            <div className="form-group">
              <label htmlFor="features">Features</label>
              <input type='text' onChange={handleChange} id="features" placeholder="Enter product features" maxLength="3000" />
            </div>
            <div className="form-group">
              <label htmlFor="cpu-model">Cpu Model</label>
              <input type='text' required onChange={handleChange} id="cpu_model" placeholder="Enter CPU Model" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="graphic-card-description">Graphic Card Description</label>
              <input type='text' onChange={handleChange} id="graphic_card_description" placeholder="Enter Graphic Card Description" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="hard-disk-size-in-gb">Hard Disk Size In GB</label>
              <input type='number' style={{
                  height: '30px',
                  borderRadius: '5px', // Border radius
                  borderColor: '#5e5e5e', // Stroke (border) color
                  borderWidth: '0.1px', // Stroke (border) width
                  borderStyle: 'solid' // Needed to apply the border
                }}onChange={handleChange} id="hard_disk_size_in_gb" placeholder="Enter Hard Disk Size" maxLength="20" />
            </div>
            <div className="form-group">
              <label htmlFor="operating-system">Operating System</label>
              <input type='text' required onChange={handleChange} id="operating_system" placeholder="Enter Operating System" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="screen-size-in-cm">Screen Size in Centimeter</label>
              <input type='number' style={{
                  height: '30px',
                  borderRadius: '5px', // Border radius
                  borderColor: '#5e5e5e', // Stroke (border) color
                  borderWidth: '0.1px', // Stroke (border) width
                  borderStyle: 'solid' // Needed to apply the border
                }}required onChange={handleChange} id="screen_size_in_cm" placeholder="Enter Screen Size" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="size">Size</label>
              <input type='text' onChange={handleChange} id="size" placeholder="Enter Size" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="special-features">Special Features</label>
              <input type='text' onChange={handleChange} id="special_features" placeholder="Enter Special Features" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="style-name">Style Name</label>
              <input type='text' onChange={handleChange} id="style_name" placeholder="Enter Style Name" maxLength="200" />
            </div>
            <div className="form-group">
              <label htmlFor="tag">Tag</label>
              <input type='text' onChange={handleChange} id="tag" placeholder="Enter Tag" maxLength="200" />
            </div>
            <FileUploader onFilesSelected={handleFiles}/>

            {/* Add submit button */}
            <Button type="submit" 
              className="submit-btn"
              variant="contained"
              color="primary"
              disabled={loader}
              startIcon={loader ? <CircularProgress size={24} /> : null}>Add Product</Button>
          </form>
        </div>
        <h1>Show Products</h1>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search products..."
            // value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <div className="show-products">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>Model</th>
                <th>Brand</th>
                <th>Color</th>
                <th>Description</th>
                <th>Features</th>
                {/* Add more columns if needed */}
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <Ripples key={index} onClick={(e)=>handleRowClick(product, e)}>
                <tr >
                  
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.sub_category}</td>
                  <td>{product.model}</td>
                  <td>{product.brand}</td>
                  <td>{product.color}</td>
                  <td>{product.description}</td>
                  <td>{product.features}</td>
                  {/* Render more product details as needed */}
                </tr>
                </Ripples>                  

              ))}
            </tbody>
          </table>
        </div>

        {/* <DataTable {...props}/> */}

        {productAdded && (
        <div className="success-popup">
          <img src='/assets/success.png' alt="Success" />
        </div>
      )}

      </div>
    );
  }

  return (
    <ProductProvider>
      <Main/>
    </ProductProvider>
  );
};

export default Products;
