import { createContext, useContext, useState, useEffect} from 'react';
import {url} from '../utils/constant'

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  useEffect(() => {
    checkSession();
  }, []);

  const handleLogin = async (email, password) => {
    const requestBody = {
      path: "/admin",
      action: "login",
      data: {
        email: email,
        password: password,
      },
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Login successful:', data);
      setUser({data:data.data});
      // Handle successful login here (e.g., saving the token, redirecting, etc.)
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleLogout = async()=>{
    setUser(null);
  }

  const checkSession = async()=>{
    try {
      const requestBody = {
        path: "/session"
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        credentials: 'include' // Necessary to include the HTTP-only cookie
      });
      

      const data = await response.json();
      if (data.status===200) {
        setUser("user")
      } else {
        setUser(null);
      }

    } catch (error) {
      console.error('Session check failed', error);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{ user, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
