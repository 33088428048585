import React, { useState } from 'react';
import './FileUploader.css'

const FileUploader = ({onFilesSelected}) => {
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  

  // Function to handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    // Assuming you want to do something with the files, e.g. upload
    const _files = e.dataTransfer.files;
    onFilesSelected(_files); // You would handle file upload here
    setFiles([...e.dataTransfer.files]);
  };

  // Function to handle file selection via browse
  const handleInputChange = (e) => {
    const _files = e.target.files;
    onFilesSelected(_files); // You would handle file upload here and
    setFiles([...e.target.files]);
  };

  // Function to handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  return (
    <div className="file-uploader">
      <div className="upload-title">Upload Photos</div>
      <div
        className={`drop-area ${dragActive ? 'active' : ''}`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        onClick={() => document.getElementById('fileInput').click()}
      >
        <div className="drop-message">
          <img src="/assets/upload-icon.svg" alt="Upload icon" />
          {files.length > 0 ? (
            <div>
              <p>Files to be uploaded:</p>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <p>Drop your image here, or <span className="browse-link">browse</span></p>
              <p>Supports: PNG, JPG, JPEG, WEBP</p>
            </div>
          )}
        </div>
        <input
          id="fileInput"
          type="file"
          multiple
          onChange={handleInputChange}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

export default FileUploader;
