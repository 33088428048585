import React,{useEffect} from 'react';
import { FaHome, FaProductHunt, FaShoppingCart, FaMoneyBillWave, FaUsers, FaCog, FaQuestionCircle, FaSignOutAlt, FaSun, FaMoon } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import './DashBoardScreen.css';
import { useState } from 'react';
import '../../components/products/Product'
import Products from '../../components/products/Product';
import {useAuth } from "../../providers/AuthProvider";
import Shop from '../../components/shops/Shop';

const DashboardScreen = () => {
  const {handleLogout} = useAuth();
  const [activeItem, setActiveItem] = useState('Dashboard');

  const handleSidebarItemClick = (itemName) => {
    setActiveItem(itemName);
    console.log(itemName);
  };

  useEffect(()=>{
    if(activeItem==='Log Out'){
      handleLogout();
    }
  },[activeItem]);

  return (
    <div className="dashboard">
      <div className="sidebar">
        <button className="create-button" onClick={() => handleSidebarItemClick('Create')}>
          <IoMdAdd />
          Create
        </button>
        
        <div className={`menu-item ${activeItem === 'Dashboard' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Dashboard')}>
          <FaHome />
          Dashboard
        </div>
        <div className={`menu-item ${activeItem === 'Products' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Products')}>
          <FaProductHunt />
          Products
        </div>
        <div className={`menu-item ${activeItem === 'Shop' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Shop')}>
          <FaShoppingCart />
          Shop
        </div>
        <div className={`menu-item ${activeItem === 'Income' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Income')}>
          <FaMoneyBillWave />
          Income
        </div>
        <div className={`menu-item ${activeItem === 'Customers' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Customers')}>
          <FaUsers />
          Customers
        </div>
        
        <hr />

        <div className={`menu-item ${activeItem === 'Settings' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Settings')}>
          <FaCog />
          Settings
        </div>
        <div className={`menu-item ${activeItem === 'Help & Privacy' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Help & Privacy')}>
          <FaQuestionCircle />
          Help & Privacy
        </div>
        <div className={`menu-item ${activeItem === 'Log Out' ? 'active' : ''}`} onClick={() => handleSidebarItemClick('Log Out')}>
          <FaSignOutAlt />
          Log Out
        </div>

        <div className="toggle-theme">
          <FaSun /> 
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
          <FaMoon />
        </div>
      </div>
      
      <div className="content">
      {(() => {
        switch (activeItem) {
          case 'Products':
            return <Products />;
          case 'Shop':
            return <Shop />;
          default:
            return null; // Or some default component
        }
      })()}
      </div>
    </div>
  );
};

export default DashboardScreen;

