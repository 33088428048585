import { createContext, useContext, useState, useEffect, useRef} from 'react';
import {url, url_auth} from '../utils/constant'

const ProductContext = createContext(null);

export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({children}) => {
    const [loader, setLoader] = useState(false);
    const [product, setProduct] = useState({
        name:'',price:'',category:'',sub_category:'',
        model:'',brand:'',color:'',description:'',features:'',
        cpu_model:'',graphic_card_description:'',hard_disk_size_in_gb:'',operating_system:'',
        screen_size_in_cm:'',size:'',special_features:'',style_name:'',tag:''
    });
    const [productAdded, setproductAdded] = useState(false);
    const timeoutRef = useRef();
    const [products, setProducts] = useState([
        {name:"",price:"",category:"", sub_category:"", model:"",brand:"",color:"", description:"", features:""},
        {name:"",price:"",category:"", sub_category:"", model:"",brand:"",color:"", description:"", features:""},
        {name:"",price:"",category:"", sub_category:"", model:"",brand:"",color:"", description:"", features:""},
        {name:"",price:"",category:"", sub_category:"", model:"",brand:"",color:"", description:"", features:""},
        {name:"",price:"",category:"", sub_category:"", model:"",brand:"",color:"", description:"", features:""}
    ]);
    const [files, setFiles] = useState([])

    const updateField = (field, value) => {
        setProduct(prevState => ({
            ...prevState,
            [field]: value
        }));
        console.log(product);
    };

    const addFiles = async(files)=>{
        setFiles(files);
    }

    const addProduct = async() =>{
        setLoader(true);

        try {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('file', files[i]);
            }
            const fileResponse = await fetch(url+'/uploads', {
                method: 'POST',
                body: formData,
                credentials: 'include'
                });
            console.log(fileResponse);
            const fileData = await fileResponse.json();
            const fileIDs = fileData.data.map(item => item.fileIDs).flat();
            updateField("images", fileIDs);
            console.log(product);

            const requestBody = {
            path: "/products",
            action: "add_product",
            data: product,
        };


            const response = await fetch(url_auth, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestBody),
              credentials: 'include'
            });
        
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
        
            const data = await response.json();
            console.log('Product added successfully', data);
            setproductAdded(true);
            setLoader(false);
            setSearchTerm(" ");
            setTimeout(()=>{
                setproductAdded(false);
            },650)
          } catch (error) {
            console.error('Login failed:', error);
          }
    }

    // ** product suggestions by name
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            getProductsByName();
        }, 500)
    }, [searchTerm]);

    const getProductsByName = async() =>{
        try {
            const requestBody = {
                path: "/products",
                action: "get_products",
                data: {name:searchTerm, match:"similar"},
            };
            const response = await fetch(url_auth, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
                credentials: 'include'
            });
        
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        
            const data = await response.json();
            console.log(data);
            setProducts(data.data);
              
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <ProductContext.Provider value={{product, addFiles, updateField, productAdded, addProduct, products, handleSearchChange, loader}}>
          {children}
        </ProductContext.Provider>
    );
}