import { AuthProvider, useAuth } from "./providers/AuthProvider";
import DashBoardScreen from "./screens/DashBoard/DashBoardScreen";
import LoginScreen from "./screens/Login/LoginScreen";
import './theme.css'


const Main = () =>{
  const {user} = useAuth();
  return (
    <div className="App">
        {user? (<DashBoardScreen/>):(<LoginScreen/>)}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Main/>
    </AuthProvider>
  );
}

export default App;
